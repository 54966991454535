var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { tabMaxWidth } from "ui/components/panels/home-menu/home-menu.style";
export var WelcomeScreenText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Disclaimer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var WelcomeScreen = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tfont-size: ", ";\n\t\t\theight: 100%;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t\tflex-direction: column;\n\t\t\tmax-width: ", ";\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tflex-grow: 1;\n\t\t\t\tfont-style: italic;\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t}\n\t\t"], ["\n\t\t\tfont-size: ", ";\n\t\t\theight: 100%;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t\tflex-direction: column;\n\t\t\tmax-width: ", ";\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\tflex-grow: 1;\n\t\t\t\tfont-style: italic;\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t}\n\t\t"])), theme.typography.fontSize.small, tabMaxWidth, Disclaimer, theme.dimensions.margin.xLarge);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
