import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useLocation } from "react-router-dom";
import * as Styled from "./wizard.style";
import { useConflictHandler } from "hooks/conflict-handler";
import { useGetDataForWizardStep } from "hooks/get-system-data";
import { selectors, useAppSelector } from "store";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { PageRoute } from "utility/url";
import { UnitSwitch } from "ui/components/elements/unit-switch/unit-switch";
import { WizardPanel } from "ui/components/panels/wizard";
export var Wizard = function () {
    var currentRoute = useLocation().pathname;
    var isLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    useConflictHandler();
    var currentPath = useLocation().pathname;
    var isSelectApplicationStep = currentPath === PageRoute.SELECT_APPLICATION;
    useGetDataForWizardStep(isSelectApplicationStep);
    return (_jsxs(Styled.WizardTab, { showToolbar: currentRoute === PageRoute.DEFINE_PARAMETERS, children: [_jsx(Styled.Toolbar, { children: currentRoute === PageRoute.DEFINE_PARAMETERS && _jsx(UnitSwitch, {}) }), _jsxs(Styled.WizardTabContent, { children: [_jsx(WizardPanel, {}), _jsx(DelayedFallback, { isLoading: isLoading, fallback: _jsx(LoadingOverlay, {}) })] })] }));
};
