var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { t } from "i18next";
import { TactonElementState, TactonResponseStatus, ComponentType, ComponentList, } from "./types";
import { componentListGroupName, componentSelectionGroupName, outputGroupName, totalLiftingForceVerticalFieldName, totalLiftingForceHorizontalFieldName, requiredLiftingForceHorizontalFieldName, requiredLiftingForceVerticalFieldName, totalVolumeFieldName, positiveInfinity, negativeInfinity, defaultCupImageMap, calculationInputGroupName, handledObjectTypeFieldName, defaultSurfaceRoughnessFieldName, surfaceStructureFieldName, defaultThroughLeakFieldName, surfaceCoatingFieldName, cardboardThroughLeakFieldName, cardboardSurfaceRoughnessFieldName, cardboardBoxSpecificationGroupName, releaseNotesGroupName, systemTemplateGroupName, adminGroupName, systemTemplateSelectedFieldName, systemTemplateNoneSelected, } from "./constants";
import { getImageUrl } from "./common-api";
import { Unit } from "utility/number";
import { NumberType, ChoiceType, SpecialGroupLayout, } from "api/types";
import { getEmptySystemData } from "api/utility/system-data";
import { ALWAYS_COMPATIBLE } from "3d/helpers";
export var errors;
(function (errors) {
    errors["STEP_NOT_FOUND"] = "unexpected format, step not found";
    errors["NO_ROOT_GROUP"] = "unexpected format, no rootGroup found";
    errors["ROOT_GROUP_HAS_FIELD"] = "unexpected format, rootGroup has field";
})(errors || (errors = {}));
/* Always interpret the longer side as "width" */
var getDimensionsFromLipSize = function (lipSize) {
    if (!lipSize)
        return { width: 0, height: 0 };
    var _a = __read(lipSize.split("x"), 2), dim1 = _a[0], dim2 = _a[1];
    var width = dim1 > dim2 ? dim1 : dim2;
    var height = dim1 > dim2 ? dim2 : dim1;
    return {
        width: Number(width) / 1000,
        height: Number(height) / 1000,
    };
};
var unitMap = {
    C: Unit.C,
    "-kPa": Unit.KPA,
    kg: Unit.KG,
    L: Unit.L,
    "m/s2": Unit.M_S2,
    N: Unit.N,
    MPa: Unit.MPA,
    s: Unit.S,
    "parts/min": Unit.PPM,
};
var getParameterAttributes = function (tactonField, groupName, ticket) {
    var description = __assign(__assign(__assign(__assign(__assign(__assign({}, (tactonField.properties.tc_field_view_text
        ? {
            long: tactonField.properties.tc_field_view_text,
        }
        : {})), (tactonField.properties.tc_info_text
        ? {
            short: tactonField.properties.tc_info_text,
        }
        : {})), (tactonField.properties.tc_field_view_picture
        ? {
            imageSource: getImageUrl(tactonField.properties.tc_field_view_picture, ticket),
        }
        : {})), (tactonField.properties.field_view_layout
        ? {
            layout: tactonField.properties.field_view_layout,
        }
        : {})), (tactonField.properties.field_view_picture_scale
        ? {
            imageScale: Number(tactonField.properties.field_view_picture_scale),
        }
        : {})), (tactonField.properties.field_view_max_size_pixels
        ? {
            maxSizePixels: Number(tactonField.properties.field_view_max_size_pixels),
        }
        : {}));
    var descriptionIsEmpty = Object.keys(description).length === 0 && description.constructor === Object;
    return __assign(__assign(__assign(__assign(__assign({ isLocked: tactonField.committed || tactonField.alwaysCommitted, groupId: groupName, id: tactonField.name, label: tactonField.description || tactonField.name }, (tactonField.properties.tc_field_view_picture
        ? {
            imageSource: getImageUrl(tactonField.properties.tc_field_view_picture, ticket),
        }
        : {})), (tactonField.properties.tc_field_view_picture_alt
        ? {
            imageSourceAlt: getImageUrl(tactonField.properties.tc_field_view_picture_alt, ticket),
        }
        : {})), (tactonField.properties.unit
        ? { unit: unitMap[tactonField.properties.unit] || undefined }
        : {})), { value: tactonField.value, hiddenInWizard: tactonField.properties.wizard_hidden
            ? tactonField.properties.wizard_hidden === "yes"
            : false, lockDisabled: tactonField.alwaysCommitted, readonly: tactonField.properties.guitype
            ? tactonField.properties.guitype === "readonly"
            : false }), (!descriptionIsEmpty && { description: description }));
};
var getParameter = function (tactonField, groupName, ticket) {
    var e_1, _a, e_2, _b;
    var _c, _d, _e, _f, _g;
    var maxValue = function (field) {
        return field.properties.max_value &&
            field.properties.max_value !== positiveInfinity
            ? Number(field.properties.max_value)
            : undefined;
    };
    var minValue = function (field) {
        return field.properties.min_value &&
            field.properties.min_value !== negativeInfinity
            ? Number(field.properties.min_value)
            : undefined;
    };
    if (tactonField.domain.name === "float") {
        return __assign(__assign({}, getParameterAttributes(tactonField, groupName, ticket)), { type: NumberType.FLOAT, max: maxValue(tactonField), min: minValue(tactonField) });
    }
    else if (tactonField.domain.name === "int") {
        return __assign(__assign({}, getParameterAttributes(tactonField, groupName, ticket)), { type: NumberType.INT, max: maxValue(tactonField), min: minValue(tactonField) });
    }
    else if (tactonField.domain.name === "application_class" ||
        tactonField.properties.piab_gui === "image") {
        var options = [];
        try {
            for (var _h = __values(tactonField.domain.elements), _j = _h.next(); !_j.done; _j = _h.next()) {
                var element = _j.value;
                var imagePath = (_c = element.properties) === null || _c === void 0 ? void 0 : _c.tc_field_view_picture;
                var imagePathAlt = (_d = element.properties) === null || _d === void 0 ? void 0 : _d.tc_field_view_picture_alt;
                if (element.state !== TactonElementState.RED) {
                    options.push(__assign(__assign({ label: element.description || element.name, value: element.name, selected: element.selected }, (imagePath && {
                        imageSource: getImageUrl(imagePath, ticket),
                    })), (imagePathAlt && {
                        imageSourceAlt: getImageUrl(imagePathAlt, ticket),
                    })));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_j && !_j.done && (_a = _h.return)) _a.call(_h);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return __assign(__assign({}, getParameterAttributes(tactonField, groupName, ticket)), { type: ChoiceType.IMAGE_SELECT, options: options });
    }
    else if (tactonField.properties.piab_gui === "slider") {
        var sliderMax = tactonField.properties.slider_max
            ? Number(tactonField.properties.slider_max)
            : 200;
        var sliderMin = tactonField.properties.slider_min
            ? Number(tactonField.properties.slider_min)
            : 200;
        var sliderMinLabel = (_e = tactonField.properties.slider_min_header) !== null && _e !== void 0 ? _e : "min";
        var sliderMaxLabel = (_f = tactonField.properties.slider_max_header) !== null && _f !== void 0 ? _f : "max";
        return __assign(__assign({}, getParameterAttributes(tactonField, groupName, ticket)), { type: ChoiceType.SLIDER, sliderMin: sliderMin, sliderMax: sliderMax, sliderMinLabel: sliderMinLabel, sliderMaxLabel: sliderMaxLabel });
    }
    else {
        var dropDownOptions = [];
        try {
            for (var _k = __values(tactonField.domain.elements), _l = _k.next(); !_l.done; _l = _k.next()) {
                var element = _l.value;
                var imagePath = (_g = element.properties) === null || _g === void 0 ? void 0 : _g.tc_field_view_picture;
                if (element.state !== TactonElementState.RED) {
                    dropDownOptions.push(__assign({ label: element.description || element.name, value: element.name, hasConflict: element.state === TactonElementState.ORANGE }, (imagePath && {
                        imageSource: getImageUrl(imagePath, ticket),
                    })));
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_l && !_l.done && (_b = _k.return)) _b.call(_k);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return __assign(__assign({}, getParameterAttributes(tactonField, groupName, ticket)), { type: ChoiceType.DROPDOWN, options: dropDownOptions });
    }
};
var getComponentFromTactonGroup = function (group, isObjectSurfaceOily) {
    var _a, _b;
    var members = group.members;
    var componentField = members[0];
    var validityField = members[1];
    var component = {
        articleNumber: componentField.properties.art_nr_code || "",
        link: componentField.properties.product_url || "",
        value: componentField.value,
        label: componentField.valueDescription,
        valid: validityField.value === "Yes",
    };
    var list = (_a = group.properties) === null || _a === void 0 ? void 0 : _a.component_list;
    var type = (_b = group.properties) === null || _b === void 0 ? void 0 : _b.selected_component;
    var isSuctionCup = list === ComponentList.SUCTION_CUP || type === ComponentType.SUCTION_CUP;
    var isVacuumPump = list === ComponentList.VACUUM_PUMP ||
        type === ComponentType.VACUUM_PUMP_CENTRAL ||
        type === ComponentType.VACUUM_PUMP_DECENTRAL;
    var isCupMountingComponent = list === ComponentList.CUP_MOUNTING || type === ComponentType.CUP_MOUNTING;
    if (isSuctionCup) {
        var dims = getDimensionsFromLipSize(componentField.properties.lip_size_mm);
        var cupFamily = componentField.properties.cup_family;
        var collapsePressure = Number(componentField.properties.collapse_pressure_kpa);
        var collapseVolume = Number(componentField.properties.collapse_volume_dm3);
        var modelCode = componentField.properties.suction_cup_id;
        var volume = Number(componentField.properties.volume_dm3);
        var defaultImage = (cupFamily && defaultCupImageMap[cupFamily]) ||
            defaultCupImageMap["other"];
        var image = component.articleNumber
            ? "img/app/cups/" + component.articleNumber + ".png"
            : "img/app/cups/" + defaultImage;
        var surfaceType = isObjectSurfaceOily ? "oily" : "dry";
        var horizontalLiftForce60 = Number(componentField.properties["horizontal_".concat(surfaceType, "_lift_force_0_60")]);
        var horizontalLiftForce90 = Number(componentField.properties["horizontal_".concat(surfaceType, "_lift_force_61_90")]);
        var verticalLiftForce60 = Number(componentField.properties["vertical_".concat(surfaceType, "_lift_force_0_60")]);
        var verticalLiftForce90 = Number(componentField.properties["vertical_".concat(surfaceType, "_lift_force_61_90")]);
        var mountingCompatibility = componentField.properties.fitting_mounting_interface;
        if (!cupFamily || !collapsePressure || !volume || !mountingCompatibility)
            return null;
        return __assign(__assign(__assign({}, component), { cupFamily: cupFamily, collapsePressure: collapsePressure, collapseVolume: collapseVolume, volume: volume, image: image, horizontalLiftForce60: horizontalLiftForce60, horizontalLiftForce90: horizontalLiftForce90, verticalLiftForce60: verticalLiftForce60, verticalLiftForce90: verticalLiftForce90, mountingCompatibility: mountingCompatibility, modelCode: modelCode }), dims);
    }
    else if (isVacuumPump) {
        var blowOffCapacity = parseBlowOffCapacity(componentField);
        var volume = Number(componentField.properties.volume_dm3);
        var model = componentField.properties.pump_id;
        var cadName = componentField.properties.cad_name;
        var isEnergySavingAvailable = componentField.properties.toggle_es === "Yes";
        var isInlineDecentralPump = componentField.properties.inline_decentral_pump === "Yes";
        var recFeedPressureMpa = componentField.properties.rec_feed_pressure_mpa;
        if (!blowOffCapacity || !model || !volume || !cadName)
            return null;
        return __assign(__assign({}, component), { blowOffCapacityFlow: blowOffCapacity[0], blowOffCapacityPressure: blowOffCapacity[1], volume: volume, model: model, cadName: cadName, isEnergySavingAvailable: isEnergySavingAvailable, isInlineDecentralPump: isInlineDecentralPump, recFeedPressureMpa: recFeedPressureMpa });
    }
    else if (isCupMountingComponent) {
        var model = componentField.properties.mounting_type;
        var volume = Number(componentField.properties.volume_dm3);
        var diameter = Number(componentField.properties.outer_diameter_mm) / 1000;
        var suctionCupCompatibility = componentField.properties.fitting_mounting_interface;
        if (!diameter || !model || !volume || !suctionCupCompatibility)
            return null;
        return __assign(__assign({}, component), { model: model, volume: volume, diameter: diameter, suctionCupCompatibility: suctionCupCompatibility });
    }
    else {
        return component;
    }
};
var getComponents = function (group, isObjectSurfaceOily) {
    var _a, _b, _c, e_3, _d;
    var _e;
    var noCupValue = "".concat(ComponentList.SUCTION_CUP, "_X");
    var suctionCupComponents = (_a = {},
        _a[noCupValue] = {
            articleNumber: "no_cup",
            height: 0,
            width: 0,
            volume: 0,
            image: "img/app/cups/no_cup.svg",
            collapsePressure: 0,
            collapseVolume: 0,
            cupFamily: "",
            modelCode: "",
            link: "",
            label: t("sidebar.edit_grip_position.no_cup_selected"),
            mountingCompatibility: ALWAYS_COMPATIBLE,
            value: noCupValue,
            valid: true,
        },
        _a);
    var noPumpValue = "".concat(ComponentList.VACUUM_PUMP, "_X");
    var vacuumPumpComponents = (_b = {},
        _b[noPumpValue] = {
            articleNumber: "no_pump",
            value: "".concat(ComponentList.VACUUM_PUMP, "_X"),
            label: t("sidebar.edit_pump.no_pump_selected"),
            link: "",
            valid: true,
            blowOffCapacityFlow: 0,
            blowOffCapacityPressure: 0,
            volume: 1,
            model: "test",
            isEnergySavingAvailable: false,
            isInlineDecentralPump: false,
            recFeedPressureMpa: "unspecified",
        },
        _b);
    var noMountingValue = "".concat(ComponentList.CUP_MOUNTING, "_X");
    var cupMountingComponents = (_c = {},
        _c[noMountingValue] = {
            articleNumber: "no_mounting",
            value: "".concat(ComponentList.CUP_MOUNTING, "_X"),
            label: t("sidebar.edit_grip_position.no_cup_mounting_selected"),
            link: "",
            valid: true,
            model: "test",
            volume: 1,
            diameter: 1,
            suctionCupCompatibility: ALWAYS_COMPATIBLE,
        },
        _c);
    try {
        for (var _f = __values(group.members), _g = _f.next(); !_g.done; _g = _f.next()) {
            var member = _g.value;
            if (!member.isGroup)
                continue;
            var subGroup = member;
            var component = getComponentFromTactonGroup(subGroup, isObjectSurfaceOily);
            if (!component)
                continue;
            var list = (_e = subGroup.properties) === null || _e === void 0 ? void 0 : _e.component_list;
            if (list === ComponentList.SUCTION_CUP) {
                suctionCupComponents[component.value] = component;
            }
            else if (list === ComponentList.VACUUM_PUMP) {
                vacuumPumpComponents[component.value] = component;
            }
            else if (list === ComponentList.CUP_MOUNTING) {
                cupMountingComponents[component.value] =
                    component;
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_d = _f.return)) _d.call(_f);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return { suctionCupComponents: suctionCupComponents, vacuumPumpComponents: vacuumPumpComponents, cupMountingComponents: cupMountingComponents };
};
var getSelections = function (group) {
    var _a, e_4, _b;
    var _c;
    var suctionCupSelections = {};
    var vacuumPumpSelectionsCentralized = {};
    var vacuumPumpSelectionsDecentralized = {};
    var cupMountingSelections = {};
    var typeMaps = (_a = {},
        _a[ComponentType.SUCTION_CUP] = {
            list: ComponentList.SUCTION_CUP,
            selections: suctionCupSelections,
        },
        _a[ComponentType.VACUUM_PUMP_CENTRAL] = {
            list: ComponentList.VACUUM_PUMP,
            selections: vacuumPumpSelectionsCentralized,
        },
        _a[ComponentType.VACUUM_PUMP_DECENTRAL] = {
            list: ComponentList.VACUUM_PUMP,
            selections: vacuumPumpSelectionsDecentralized,
        },
        _a[ComponentType.CUP_MOUNTING] = {
            list: ComponentList.CUP_MOUNTING,
            selections: cupMountingSelections,
        },
        _a);
    var subGroups = group.members;
    try {
        for (var subGroups_1 = __values(subGroups), subGroups_1_1 = subGroups_1.next(); !subGroups_1_1.done; subGroups_1_1 = subGroups_1.next()) {
            var subGroup = subGroups_1_1.value;
            if (!subGroup.isGroup)
                continue;
            var members = subGroup.members;
            var componentField = members[0];
            var type = (_c = subGroup.properties) === null || _c === void 0 ? void 0 : _c.selected_component;
            if (!type)
                continue;
            if (componentField.value === "".concat(typeMaps[type].list, "_X")) {
                typeMaps[type].selections[componentField.name] = null;
            }
            else {
                typeMaps[type].selections[componentField.name] = componentField.value;
            }
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (subGroups_1_1 && !subGroups_1_1.done && (_b = subGroups_1.return)) _b.call(subGroups_1);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return {
        suctionCupSelections: suctionCupSelections,
        vacuumPumpSelectionsCentralized: vacuumPumpSelectionsCentralized,
        vacuumPumpSelectionsDecentralized: vacuumPumpSelectionsDecentralized,
        cupMountingSelections: cupMountingSelections,
    };
};
var getStringValue = function (group, fieldName) {
    var member = group.members.find(function (member) { return member.name === fieldName; });
    if (!member)
        return "";
    var field = member;
    return field.value;
};
var getNumericalValue = function (group, fieldName) {
    var member = group.members.find(function (member) { return member.name === fieldName; });
    if (!member)
        return 0;
    var field = member;
    return Number(field.value);
};
/* The values for Calculation input will need to get their values for throughLeak and surfaceRoughness
 * from different groups based on the active application. If the application is cardboard we need the values to be fetched
 * from the box_specification_group whereas we otherwise get those values from the calculation_input_group. */
var getCalculationInput = function (groups, calculationInputGroup) {
    var cardboardBoxSpecificationGroup = groups.find(function (group) { return group.name === cardboardBoxSpecificationGroupName; });
    var handledObjectType = getStringValue(calculationInputGroup, handledObjectTypeFieldName);
    var surfaceStructure = getStringValue(calculationInputGroup, surfaceStructureFieldName);
    var surfaceCoating = getStringValue(calculationInputGroup, surfaceCoatingFieldName);
    var surfaceRoughness = getNumericalValue(calculationInputGroup, defaultSurfaceRoughnessFieldName);
    var throughLeak = getNumericalValue(calculationInputGroup, defaultThroughLeakFieldName);
    if (cardboardBoxSpecificationGroup) {
        surfaceRoughness = getNumericalValue(cardboardBoxSpecificationGroup, cardboardSurfaceRoughnessFieldName);
        throughLeak = getNumericalValue(cardboardBoxSpecificationGroup, cardboardThroughLeakFieldName);
    }
    return {
        handledObjectType: handledObjectType,
        surfaceRoughness: surfaceRoughness,
        surfaceStructure: surfaceStructure,
        surfaceCoating: surfaceCoating,
        throughLeak: throughLeak,
    };
};
var getOutput = function (group) {
    return {
        totalLiftingForceVertical: getNumericalValue(group, totalLiftingForceVerticalFieldName),
        totalLiftingForceHorizontal: getNumericalValue(group, totalLiftingForceHorizontalFieldName),
        requiredLiftingForceVertical: getNumericalValue(group, requiredLiftingForceVerticalFieldName),
        requiredLiftingForceHorizontal: getNumericalValue(group, requiredLiftingForceHorizontalFieldName),
        totalVolume: getNumericalValue(group, totalVolumeFieldName),
    };
};
export var getReleaseNotes = function (group) {
    var releaseNoteGroup = group.members.find(function (member) { return member.name === releaseNotesGroupName; });
    if (!releaseNoteGroup) {
        return;
    }
    var releaseNotes = releaseNoteGroup.members.map(function (member) {
        var _a;
        var releaseNoteField = member;
        var releaseNoteText = releaseNoteField.properties.release_note;
        var changes = releaseNoteText === null || releaseNoteText === void 0 ? void 0 : releaseNoteText.split("\\n");
        return {
            changes: changes !== null && changes !== void 0 ? changes : [],
            date: (_a = releaseNoteField.properties.date) !== null && _a !== void 0 ? _a : "",
            id: releaseNoteField.value,
        };
    });
    return releaseNotes.sort(function (a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime(); });
};
var createSelectTemplateGroup = function (id) { return ({
    id: id,
    title: "",
    hiddenInWizard: false,
    specialGroupLayout: SpecialGroupLayout.DEFAULT,
}); };
var createSelectTemplateField = function (templateField, templates, groupId, label) { return ({
    type: ChoiceType.DROPDOWN,
    options: [
        { value: systemTemplateNoneSelected, label: "", hasConflict: false },
    ].concat(templates.map(function (option) { return ({
        value: option.value,
        label: option.description || "",
        hasConflict: false,
    }); })),
    id: templateField.name,
    value: templateField.value,
    isLocked: false,
    hiddenInWizard: false,
    lockDisabled: true,
    label: label,
    groupId: groupId,
}); };
// I wrote this method because I thought we needed it, but we don't currently. But I think it's a good idea to keep this
// method as a comment. That way its easy to use if we ever need to look at the current application when serializing tacton data.
/* const getActiveApplication = (groups: TactonGroup[]) => {
    const applicationSelectionGroup = groups.find(
        (group) => group.name === "application_selection_group",
    );
    const selectedApplicationField = applicationSelectionGroup?.members.find(
        (member) => member.name === "application_selected_field",
    ) as TactonField | undefined;

    const activeApplication =
        selectedApplicationField?.value as TactonApplication;

    return activeApplication;
}; */
export var deserializeSystemDataParameters = function (response, ticket) {
    var e_5, _a, e_6, _b, e_7, _c, e_8, _d;
    var _e, _f, _g;
    var step = response.steps.find(function (step) { return step.current; });
    if (!step) {
        throw Error(errors.STEP_NOT_FOUND);
    }
    var activeStep = step;
    var rootGroup = activeStep.rootGroup;
    var groups = rootGroup.members;
    var systemData = __assign(__assign({}, getEmptySystemData()), { currentStep: step.current });
    var componentListGroup;
    /* Store hidden parameters for use in conflicts further down*/
    var hiddenParameters = {};
    try {
        for (var groups_1 = __values(groups), groups_1_1 = groups_1.next(); !groups_1_1.done; groups_1_1 = groups_1.next()) {
            var group = groups_1_1.value;
            if (!group.isGroup) {
                continue;
            }
            if (group.name === componentListGroupName) {
                componentListGroup = group;
                continue;
            }
            if (group.name === componentSelectionGroupName) {
                var selections = getSelections(group);
                systemData = __assign(__assign({}, systemData), selections);
                continue;
            }
            if (group.name === outputGroupName) {
                var output = getOutput(group);
                systemData = __assign(__assign({}, systemData), { output: output });
                continue;
            }
            if (group.name === calculationInputGroupName) {
                var calculationInput = getCalculationInput(groups, group);
                systemData = __assign(__assign({}, systemData), { calculationInput: calculationInput });
                continue;
            }
            if (group.name === adminGroupName) {
                var releaseNotes = getReleaseNotes(group);
                if (releaseNotes) {
                    systemData = __assign(__assign({}, systemData), { releaseNotes: releaseNotes });
                }
                var templateGroup = group.members.find(function (member) { return member.name === systemTemplateGroupName; });
                var templateField = group.members.find(function (group) { return group.name === systemTemplateSelectedFieldName; });
                if (!templateGroup || !templateField)
                    continue;
                var templates = templateGroup.members;
                var groupId = "select-application-template";
                systemData.parameterGroups[group.name] =
                    createSelectTemplateGroup(groupId);
                systemData.parameters[templateField.name] = createSelectTemplateField(templateField, templates, groupId, templateGroup.description || "");
                systemData.templateCode = templateField === null || templateField === void 0 ? void 0 : templateField.properties.code;
                continue;
            }
            var isGroupHidden = group.properties && group.properties.hidden === "yes";
            var isGroupHiddenInWizard = group.properties && group.properties.wizard_hidden
                ? group.properties.wizard_hidden === "yes"
                : false;
            var groupSpecialLayout = ((_f = (_e = group.properties) === null || _e === void 0 ? void 0 : _e.group_renderer) !== null && _f !== void 0 ? _f : SpecialGroupLayout.DEFAULT);
            var members = group.members;
            try {
                for (var members_1 = (e_6 = void 0, __values(members)), members_1_1 = members_1.next(); !members_1_1.done; members_1_1 = members_1.next()) {
                    var member = members_1_1.value;
                    if (member.isGroup) {
                        var properties = member.properties;
                        var subGroup = __assign({ id: member.name, memberIds: member.members.map(function (field) { return field.name; }) }, (properties
                            ? {
                                properties: {
                                    fieldSeparator: properties.field_separator,
                                    groupImage: properties.tc_group_picture &&
                                        getImageUrl(properties.tc_group_picture, ticket),
                                },
                            }
                            : {}));
                        systemData.parameterSubGroups.push(subGroup);
                    }
                    try {
                        for (var _h = (e_7 = void 0, __values(member.isGroup
                            ? member.members
                            : [member])), _j = _h.next(); !_j.done; _j = _h.next()) {
                            var param = _j.value;
                            if (param.isParameter) {
                                var parameter = getParameter(param, group.name, ticket);
                                var isParameterHidden = member.properties && member.properties.hidden === "yes";
                                if (isParameterHidden || isGroupHidden) {
                                    hiddenParameters[param.name] = parameter;
                                }
                                else {
                                    systemData.parameters[param.name] = parameter;
                                }
                            }
                        }
                    }
                    catch (e_7_1) { e_7 = { error: e_7_1 }; }
                    finally {
                        try {
                            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                        }
                        finally { if (e_7) throw e_7.error; }
                    }
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (members_1_1 && !members_1_1.done && (_b = members_1.return)) _b.call(members_1);
                }
                finally { if (e_6) throw e_6.error; }
            }
            if (!isGroupHidden) {
                systemData.parameterGroups[group.name] = {
                    id: group.name,
                    title: group.description || "",
                    hiddenInWizard: isGroupHiddenInWizard,
                    specialGroupLayout: groupSpecialLayout,
                };
            }
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (groups_1_1 && !groups_1_1.done && (_a = groups_1.return)) _a.call(groups_1);
        }
        finally { if (e_5) throw e_5.error; }
    }
    var isObjectSurfaceOily = ((_g = systemData.parameters.oily_object_field) === null || _g === void 0 ? void 0 : _g.value) === "Yes";
    var componentLists = componentListGroup &&
        getComponents(componentListGroup, isObjectSurfaceOily);
    systemData = __assign(__assign({}, systemData), componentLists);
    if (response.response.status === TactonResponseStatus.RESOLVABLE) {
        var conflictResponse = response.response;
        if (!systemData.parameters[conflictResponse.parameter])
            return systemData;
        var proposedChanges = [];
        try {
            for (var _k = __values(conflictResponse.changed), _l = _k.next(); !_l.done; _l = _k.next()) {
                var change = _l.value;
                var changeParameter = systemData.parameters[change.name] || hiddenParameters[change.name];
                if (!changeParameter)
                    continue;
                proposedChanges.push({
                    oldParameter: __assign(__assign({}, changeParameter), { value: change.oldValue }),
                    newParameter: __assign(__assign({}, changeParameter), { value: change.newValue }),
                });
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_l && !_l.done && (_d = _k.return)) _d.call(_k);
            }
            finally { if (e_8) throw e_8.error; }
        }
        systemData.conflict = {
            conflictingParameter: __assign(__assign({}, systemData.parameters[conflictResponse.parameter]), { value: conflictResponse.value }),
            proposedChanges: proposedChanges,
        };
    }
    return systemData;
};
var parseBlowOffCapacity = function (componentField) {
    if (typeof componentField.properties.bo_capacity_nls === "string") {
        var numberArray = componentField.properties.bo_capacity_nls.split(",");
        return [Number(numberArray[0]), Number(numberArray[1])];
    }
    else {
        return [0, 0];
    }
};
