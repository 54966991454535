import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "./logo.style";
import { PageRoute } from "utility/url";
import { useSelectApplication } from "hooks/select-application";
export var Logo = function (props) {
    var isInverted = props.isInverted;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var onApplicationNameClick = useCallback(function () {
        navigate(PageRoute.WELCOME_SCREEN);
    }, [navigate]);
    var currentApplicationLabel = useSelectApplication().currentApplicationLabel;
    return (_jsxs(Styled.Logo, { "data-testid": "logo-root", isInverted: isInverted, onClick: onApplicationNameClick, children: [_jsx(Styled.ImageContainer, { children: _jsx(Styled.Image, { alt: "logo", src: isInverted ? "img/logo-black.webp" : "img/logo.webp" }) }), _jsxs(Styled.Title, { children: [_jsx(Styled.ApplicationName, { children: t("application_title") }), _jsx(Styled.MaterialLabel, { children: currentApplicationLabel })] })] }));
};
