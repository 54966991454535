var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledWizardNav } from "@animech/ui";
export var prefix = "wizard-panel";
export var ParameterColumns = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, columns = _a.columns;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: repeat(", ", 1fr);\n\t\t\tgrid-gap: ", "\n\t\t\t\t", ";\n\t\t\tmargin-right: 10px;\n\t\t"], ["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: repeat(", ", 1fr);\n\t\t\tgrid-gap: ", "\n\t\t\t\t", ";\n\t\t\tmargin-right: 10px;\n\t\t"])), columns, theme.dimensions.margin.small, theme.dimensions.margin.large);
});
export var WizardPanel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, hidePreviousButton = _a.hidePreviousButton;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\t.", "-container {\n\t\t\t\tmin-height: 450px;\n\t\t\t\tbackground: transparent;\n\n\t\t\t\th5 {\n\t\t\t\t\tmargin-bottom: 0;\n\t\t\t\t\ttext-align: center;\n\t\t\t\t}\n\n\t\t\t\t.", "-error {\n\t\t\t\t\tmargin-top: ", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\twidth: 200px;\n\t\t\t\t", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbackground: transparent !important;\n\t\t\t\tborder: none !important;\n\t\t\t\tmargin-top: ", ";\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t\tpadding: 0;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t}\n\t\t"], ["\n\t\t\t.", "-container {\n\t\t\t\tmin-height: 450px;\n\t\t\t\tbackground: transparent;\n\n\t\t\t\th5 {\n\t\t\t\t\tmargin-bottom: 0;\n\t\t\t\t\ttext-align: center;\n\t\t\t\t}\n\n\t\t\t\t.", "-error {\n\t\t\t\t\tmargin-top: ", ";\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\twidth: 200px;\n\t\t\t\t", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbackground: transparent !important;\n\t\t\t\tborder: none !important;\n\t\t\t\tmargin-top: ", ";\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t\tpadding: 0;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\t", ";\n\t\t\t}\n\t\t"])), prefix, prefix, theme.dimensions.margin.base, StyledWizardNav.Stepper, hidePreviousButton && "display: none", StyledWizardNav.Root, theme.dimensions.margin.xLarge, theme.dimensions.margin.base, StyledWizardNav.Previous, hidePreviousButton && "display: none");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
