import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect } from "react";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { imageSelectOptions } from "store/system-data/helpers";
import { CustomImageSelect } from "ui/components/elements/input/image-select";
export var MaterialSelect = function (_a) {
    var onSelectMaterial = _a.onSelectMaterial, value = _a.value;
    var selectApplicationParameter = useAppSelector(selectors.selectSelectApplicationParameter);
    var isLoadingSelectApplication = useAppSelector(selectors.selectIsLoadingSelectApplication);
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (isLoadingSelectApplication) {
            return;
        }
        if (!selectApplicationParameter) {
            dispatch(actions.getSystemDataSelectApplication());
        }
    }, [dispatch, isLoadingSelectApplication, selectApplicationParameter]);
    if (!selectApplicationParameter) {
        return null;
    }
    var options = imageSelectOptions(selectApplicationParameter);
    return (_jsx(CustomImageSelect, { value: value !== undefined ? value : selectApplicationParameter.value, items: options.items, itemLabels: options.itemLabels, itemImages: options.itemImages, onValueChange: onSelectMaterial, description: selectApplicationParameter.description }));
};
