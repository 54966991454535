import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Label, useModal } from "@animech/ui";
import { Fragment } from "react";
import * as Styled from "./topbar.style";
import { CalculateButton } from "./calculate-button";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { OverlayData } from "store/types";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { Toggle } from "ui/components/elements/toggle";
import { Switch } from "ui/components/elements/switch";
import { useSystemWarningMessages, useSystemErrorMessages, useSystemInfoMessages, } from "hooks/messages";
import { HowToUseModal } from "ui/components/modals/how-to-use";
import { UndoRedo } from "ui/components/elements/undo-redo";
import { UnitSwitch } from "ui/components/elements/unit-switch/unit-switch";
import { IconButton } from "ui/components/elements/icons/icon-button";
import { useNewsFlashModal } from "hooks/newsflash";
export var TopbarPanel = function () {
    var openModal = useModal();
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var _a = useSystemWarningMessages(), warningMessages = _a.messages, isLoadingWarningMessages = _a.isLoading;
    var _b = useSystemErrorMessages(), errorMessages = _b.messages, isLoadingErrorMessages = _b.isLoading;
    var _c = useSystemInfoMessages(), infoMessages = _c.messages, isLoadingInfoMessages = _c.isLoading;
    useNewsFlashModal();
    var systemPerformanceData = useAppSelector(selectors.selectActivePerformanceData);
    var hasReachedPartSecured = useAppSelector(selectors.selectHasReachedPartSecured);
    var showEnergySavePerformance = useAppSelector(selectors.selectShowEnergySave);
    var overlayData = useAppSelector(selectors.selectOverlayData);
    var selectedId = useAppSelector(selectors.selectSelectedId);
    var toggleShowPerformanceData = function () {
        dispatch(actions.setOverlayData(overlayData !== OverlayData.PERFORMANCE_DATA
            ? OverlayData.PERFORMANCE_DATA
            : undefined));
    };
    var toggleShowVacuumLevelData = function (value) {
        dispatch(actions.setOverlayData(value));
    };
    var widgets = [
        {
            widget: (_jsx(IconButton, { icon: "document-list", color: "inverted", appearance: "solid", onClick: function () {
                    openModal(_jsx(HowToUseModal, {}));
                }, variant: "rectangle" })),
            label: t("topbar.ovm_pro_3d"),
            shouldRender: true,
        },
        {
            widget: _jsx(UndoRedo, {}),
            label: t("topbar.undo_redo"),
            shouldRender: true,
        },
        {
            widget: _jsx(UnitSwitch, {}),
            label: "".concat(t("topbar.metric"), "/").concat(t("topbar.imperial")),
            shouldRender: true,
        },
        {
            widget: (_jsx(Switch, { options: [
                    {
                        label: t("topbar.toggle_vacuum_data_off"),
                        value: undefined,
                        disabled: false,
                    },
                    {
                        label: t("topbar.toggle_vacuum_data_ps"),
                        value: OverlayData.VACUUM_LEVEL_PS,
                        disabled: !hasReachedPartSecured,
                    },
                    {
                        label: t("topbar.toggle_vacuum_data_es"),
                        value: OverlayData.VACUUM_LEVEL_ES,
                        disabled: !showEnergySavePerformance,
                    },
                ], value: overlayData === OverlayData.VACUUM_LEVEL_PS ||
                    overlayData === OverlayData.VACUUM_LEVEL_ES
                    ? overlayData
                    : undefined, offIndex: 0, onValueChange: function (value) {
                    return toggleShowVacuumLevelData(value);
                } })),
            label: t("topbar.toggle_vacuum_data"),
            shouldRender: systemPerformanceData && !selectedId,
        },
        {
            widget: (_jsx(Toggle, { checked: overlayData === OverlayData.PERFORMANCE_DATA, onChange: toggleShowPerformanceData })),
            label: t("topbar.toggle_performance_data"),
            shouldRender: systemPerformanceData && !selectedId,
        },
    ].filter(function (_a) {
        var shouldRender = _a.shouldRender;
        return shouldRender;
    });
    return (_jsxs(Styled.TopbarPanel, { children: [_jsx(Styled.TopBarWidgetsContainer, { children: widgets.map(function (_a) {
                    var widget = _a.widget, label = _a.label;
                    return (_jsxs(Fragment, { children: [_jsx(Styled.WidgetRow, { children: widget }), _jsx(Styled.LabelRow, { children: _jsx(Label, { size: "small", children: label }) })] }, label));
                }) }), _jsxs("div", { className: "".concat(Styled.prefix, "-left-aligned"), children: [_jsx(MessagesPopover, { messages: infoMessages, isLoading: isLoadingInfoMessages, level: MessageLevel.INFO }), _jsx(MessagesPopover, { messages: warningMessages, isLoading: isLoadingWarningMessages, level: MessageLevel.WARNING }), _jsx(MessagesPopover, { messages: errorMessages, level: MessageLevel.ERROR, isLoading: isLoadingErrorMessages, openOnChange: true }), _jsx(CalculateButton, {})] })] }));
};
