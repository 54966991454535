var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button } from "@animech/ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Styled from "./calculate-button.style";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { TimedNotification } from "ui/components/elements/timed-notification";
export var CalculateButton = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var _a = __read(useState(false), 2), showWarning = _a[0], setShowWarning = _a[1];
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var sanityCheckData = useAppSelector(selectors.selectSanityCheckData);
    var isPerformanceDataValid = useAppSelector(selectors.selectIsPerformanceDataValid);
    var hasGripPositionsWithMissingComponents = useAppSelector(selectors.selectHasGripPositionsWithMissingComponents);
    var canSendCalculationRequest = useAppSelector(selectors.selectCalculatePerformanceEnabled);
    var isDecentralizedSystem = !useAppSelector(selectors.selectIsCentralizedSystem);
    var _b = __read(useState(0), 2), countdownTimer = _b[0], setCountdownTimer = _b[1];
    var formattedCalculationTime = (sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.estimatedCalculationTime)
        ? t("topbar.estimated_calculation_time", {
            time: Math.round(sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.estimatedCalculationTime),
        })
        : t("topbar.calculating");
    useEffect(function () {
        setCountdownTimer(sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.estimatedCalculationTime);
    }, [sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.estimatedCalculationTime]);
    useEffect(function () {
        countdownTimer > 0 &&
            isSystemPerformanceLoading &&
            setTimeout(function () { return setCountdownTimer(countdownTimer - 1); }, 1000);
    }, [countdownTimer, isSystemPerformanceLoading]);
    var formattedCalculationCountdown = countdownTimer
        ? t("topbar.elapsed_calculation_time", { time: Math.round(countdownTimer) })
        : t("topbar.calculating");
    var onCalculate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (hasGripPositionsWithMissingComponents) {
                setShowWarning(true);
                return [2 /*return*/];
            }
            dispatch(actions.showPerformanceData());
            return [2 /*return*/];
        });
    }); }, [dispatch, hasGripPositionsWithMissingComponents]);
    var calculateButtonText = useMemo(function () {
        return isPerformanceDataValid && canSendCalculationRequest
            ? t("topbar.calculate_button_complete")
            : t("topbar.calculate_button");
    }, [canSendCalculationRequest, isPerformanceDataValid, t]);
    var calculateButtonColor = useMemo(function () {
        return isPerformanceDataValid || !canSendCalculationRequest
            ? "primary"
            : "tertiary";
    }, [canSendCalculationRequest, isPerformanceDataValid]);
    return (_jsxs(Styled.ButtonContainer, { children: [_jsxs(Button, { onClick: onCalculate, disabled: !canSendCalculationRequest, color: calculateButtonColor, children: [calculateButtonText, isSystemPerformanceLoading && _jsx(LoadingOverlay, {})] }), (sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.estimatedCalculationTime) !== undefined && (_jsxs(Styled.CalculationTimeContainer, { children: [_jsx(Styled.CalculationTime, { children: formattedCalculationTime }), isSystemPerformanceLoading && (_jsx(Styled.CalculationCountdown, { children: formattedCalculationCountdown }))] })), showWarning && (_jsx(TimedNotification, { text: t("notifications.empty_grip_positions".concat(isDecentralizedSystem ? "_decentralized" : "")), timeout: 10000, color: "alert", onClose: function () { return setShowWarning(false); } }))] }));
};
