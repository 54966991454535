var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var MaterialOptions = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tgap: ", " ", ";\n\t\t\tmargin: ", " 0;\n\t\t\tpadding: 0 ", ";\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tgap: ", " ", ";\n\t\t\tmargin: ", " 0;\n\t\t\tpadding: 0 ", ";\n\t\t"])), theme.dimensions.margin.small, theme.dimensions.margin.large, theme.dimensions.margin.base, theme.dimensions.margin.small);
});
var templateObject_1, templateObject_2;
