var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from "@reduxjs/toolkit";
import { systemDataConstants } from "api/system-data";
export var selectSystemDataRootSelectApplication = function (state) {
    return state.systemDataSelectApplication;
};
export var selectSystemDataSelectApplication = function (state) {
    return selectSystemDataRootSelectApplication(state).dataState.data;
};
export var selectIsSystemDataSelectApplicationCurrentStep = function (state) { var _a; return !!((_a = selectSystemDataSelectApplication(state)) === null || _a === void 0 ? void 0 : _a.currentStep); };
export var selectIsLoadingSelectApplication = function (state) {
    return selectSystemDataRootSelectApplication(state).dataState.isLoading;
};
export var selectErrorsSelectApplication = function (state) {
    return selectSystemDataRootSelectApplication(state).dataState.errors;
};
export var selectPendingParametersSelectApplication = function (state) { var _a; return (_a = selectSystemDataRootSelectApplication(state).dataState.pending) === null || _a === void 0 ? void 0 : _a.parameters; };
export var selectSelectApplicationParameter = createSelector(selectSystemDataSelectApplication, function (systemData) {
    if (!systemData)
        return undefined;
    return systemData.parameters[systemDataConstants.parameterNames.applicationField];
});
export var selectActiveApplication = createSelector(selectSelectApplicationParameter, function (applicationField) {
    if (!applicationField)
        return undefined;
    return applicationField.value;
});
export var selectApplicationNames = createSelector(selectSystemDataSelectApplication, function (systemData) {
    if (!systemData)
        return undefined;
    var applicationNames = systemData.parameters[systemDataConstants.parameterNames.applicationField];
    return applicationNames;
});
export var selectGroupedParametersSelectApplication = createSelector(selectSystemDataSelectApplication, function (systemData) {
    if (!systemData)
        return [];
    return Object.values(systemData.parameterGroups).map(function (group) {
        return __assign(__assign({}, group), { parameters: Object.values(systemData.parameters).filter(function (parameter) { return parameter.groupId === group.id; }) });
    });
});
export var selectReleaseNotes = createSelector(selectSystemDataSelectApplication, function (systemData) {
    if (!systemData)
        return undefined;
    return systemData.releaseNotes;
});
export var selectApplicationTemplateCode = createSelector(selectSystemDataSelectApplication, function (systemData) {
    return systemData === null || systemData === void 0 ? void 0 : systemData.templateCode;
});
