import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Icon } from "@animech/ui";
import * as Styled from "./image-select.style";
import { InputContainer } from "ui/components/elements/input/input-container";
export var CustomImageSelect = function (_a) {
    var valueFromProps = _a.value, items = _a.items, itemLabels = _a.itemLabels, itemImages = _a.itemImages, onValueChangeFromProps = _a.onValueChange, error = _a.error, description = _a.description;
    var valueIndex = items.findIndex(function (item) { return item === valueFromProps; });
    var onValueChange = function (newValue) {
        if (!onValueChangeFromProps || newValue === valueFromProps)
            return;
        onValueChangeFromProps(newValue);
    };
    return (_jsx(_Fragment, { children: items.map(function (item, index) { return (_jsx(InputContainer, { label: itemLabels[index], labelPosition: "under", error: error, description: description, children: _jsxs(Styled.ImageContainer, { selected: valueIndex === index, children: [_jsx(Styled.Image, { onClick: function () { return onValueChange(item); }, src: itemImages[index], alt: itemLabels[index] }), _jsx(Styled.IconContainer, { children: _jsx(Icon, { name: "checkbox" }) })] }) }, String(item))); }) }));
};
