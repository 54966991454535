var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReleaseNote } from "ui/components/elements/release-notes/release-notes.style";
import { tabMaxWidth } from "ui/components/panels/home-menu/home-menu.style";
export var Footer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var LatestUpdatesRoot = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\theight: 100%;\n\t\t\tmax-width: ", ";\n\t\t\t", " {\n\t\t\t\tbackground: ", ";\n\t\t\t}\n\t\t"], ["\n\t\t\theight: 100%;\n\t\t\tmax-width: ", ";\n\t\t\t", " {\n\t\t\t\tbackground: ", ";\n\t\t\t}\n\t\t"])), tabMaxWidth, ReleaseNote, theme.colors.palette.grey[800]);
});
var templateObject_1, templateObject_2, templateObject_3;
