var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as Sentry from "@sentry/react";
import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import { getAllAssignedSelectionFields, getFailedComponents, getFailedParameters, getNextAvailableSelectionId, } from "store/system-data/helpers";
import { ComponentList, TactonStepName } from "api/tacton/types";
import api, { systemDataConstants } from "api/system-data";
import { ApiErrorType } from "api/errors";
import { getEmptySystemData } from "api/utility/system-data";
export var initialState = {
    cupSelectionMap: {},
    pumpSelectionCentralMap: {},
    pumpSelectionDecentralMap: {},
    cupMountingSelectionMap: {},
    dataState: { isLoading: false },
};
var setSystemDataDefineCurrentStep = function (state, action) {
    if (state.dataState.data) {
        state.dataState.data.currentStep = action.payload;
    }
};
var assignCupSelection = function (state, action) {
    if (!state.dataState.data || !action.payload)
        return;
    var selectionId = getNextAvailableSelectionId(state.dataState.data.suctionCupSelections, state.cupSelectionMap);
    if (!selectionId)
        return;
    state.cupSelectionMap[action.payload] = selectionId;
};
var assignCupMountingSelection = function (state, action) {
    if (!state.dataState.data || !action.payload)
        return;
    var selectionId = getNextAvailableSelectionId(state.dataState.data.cupMountingSelections, state.cupMountingSelectionMap);
    if (!selectionId)
        return;
    state.cupMountingSelectionMap[action.payload] = selectionId;
};
var assignDecentralPumpSelection = function (state, action) {
    if (!state.dataState.data || !action.payload)
        return;
    var selectionId = getNextAvailableSelectionId(state.dataState.data.vacuumPumpSelectionsDecentralized, state.pumpSelectionDecentralMap);
    if (!selectionId)
        return;
    state.pumpSelectionDecentralMap[action.payload] = selectionId;
};
var assignCentralPumpSelection = function (state, action) {
    if (!state.dataState.data || !action.payload)
        return;
    var selectionId = getNextAvailableSelectionId(state.dataState.data.vacuumPumpSelectionsCentralized, state.pumpSelectionCentralMap);
    if (!selectionId)
        return;
    if (!selectionId)
        return;
    state.pumpSelectionCentralMap[action.payload] = selectionId;
};
var unassignAllSelections = function (state, action) {
    action.payload.forEach(function (nodeId) {
        delete state.cupSelectionMap[nodeId];
        delete state.cupMountingSelectionMap[nodeId];
        delete state.pumpSelectionCentralMap[nodeId];
        delete state.pumpSelectionDecentralMap[nodeId];
    });
};
var setCardboardManualThroughLeak = function (state, action) {
    if (!state.manualCalculationInput) {
        state.manualCalculationInput = {
            manualThroughLeak: action.payload,
        };
    }
    else {
        state.manualCalculationInput.manualThroughLeak = action.payload;
    }
};
var setCardboardManualSurfaceRoughness = function (state, action) {
    if (!state.manualCalculationInput) {
        state.manualCalculationInput = {
            manualSurfaceRoughness: action.payload,
        };
    }
    else {
        state.manualCalculationInput.manualSurfaceRoughness = action.payload;
    }
};
var getData = createAsyncThunk("system-data/getData", function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_1, error, errors, setPending, pendingParameters, parameterNames_1, parameterValues_1, response, systemDataState, pendingSelections, assignedFields_1, selectionFieldNames_1, selectionValues_1, response;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api.getData(thunkApi.extra.configId, TactonStepName.DEFINE_PARAMETERS, thunkApi.extra.ticket)];
            case 1:
                response = _c.sent();
                data = response;
                return [3 /*break*/, 3];
            case 2:
                err_1 = _c.sent();
                error = err_1;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        errors: [
                            { message: error.message, type: error.type, extra: error.extra },
                        ],
                    })];
            case 3:
                errors = [];
                setPending = function (parameterNames, parameterValues, onError) { return __awaiter(void 0, void 0, void 0, function () {
                    var response, err_2, error;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (parameterNames.length === 0)
                                    return [2 /*return*/];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, api.submitParameters(thunkApi.extra.configId, parameterNames, parameterValues, thunkApi.extra.ticket)];
                            case 2:
                                response = _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                err_2 = _a.sent();
                                error = err_2;
                                onError(error);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/, response];
                        }
                    });
                }); };
                pendingParameters = (_a = thunkApi.getState().systemDataDefineParameters.dataState.pending) === null || _a === void 0 ? void 0 : _a.parameters;
                if (!pendingParameters) return [3 /*break*/, 5];
                parameterNames_1 = Object.keys(pendingParameters);
                parameterValues_1 = parameterNames_1.map(function (p) { return pendingParameters[p]; });
                return [4 /*yield*/, setPending(parameterNames_1, parameterValues_1, function (err) {
                        var errorData = err.systemData;
                        var failedParameters = errorData
                            ? getFailedParameters(parameterNames_1, parameterValues_1, errorData)
                            : undefined;
                        errors.push({
                            message: err.message,
                            type: ApiErrorType.RESTORE_SAVED_ERROR,
                            extra: {
                                parameters: failedParameters,
                            },
                        });
                        if (errorData) {
                            data = errorData;
                        }
                    })];
            case 4:
                response = _c.sent();
                if (response) {
                    data = response;
                }
                _c.label = 5;
            case 5:
                systemDataState = thunkApi.getState().systemDataDefineParameters;
                pendingSelections = (_b = systemDataState.dataState.pending) === null || _b === void 0 ? void 0 : _b.selections;
                if (!pendingSelections) return [3 /*break*/, 7];
                assignedFields_1 = getAllAssignedSelectionFields(systemDataState);
                selectionFieldNames_1 = Object.keys(pendingSelections).filter(function (fieldName) { return assignedFields_1.includes(fieldName); });
                selectionValues_1 = selectionFieldNames_1.map(function (p) { return pendingSelections[p]; });
                return [4 /*yield*/, setPending(selectionFieldNames_1, selectionValues_1, function (err) {
                        var errorData = err.systemData;
                        var failedComponents = errorData
                            ? getFailedComponents(selectionFieldNames_1, selectionValues_1, errorData)
                            : undefined;
                        errors.push({
                            message: err.message,
                            type: ApiErrorType.RESTORE_SAVED_ERROR,
                            extra: { components: failedComponents },
                        });
                        if (errorData) {
                            data = errorData;
                        }
                    })];
            case 6:
                response = _c.sent();
                if (response) {
                    data = response;
                }
                _c.label = 7;
            case 7:
                if (errors.length > 0) {
                    return [2 /*return*/, thunkApi.rejectWithValue({ errors: errors, data: data })];
                }
                return [2 /*return*/, data];
        }
    });
}); }, {
    condition: function (_, thunkApi) {
        var systemData = thunkApi.getState().systemDataDefineParameters;
        return !systemData.dataState.isLoading;
    },
});
var setParameters = createAsyncThunk("system-data/setParameters", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3, error, errorData, failedParameters, failedComponents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api.submitParameters(thunkApi.extra.configId, payload.parameters, payload.values, thunkApi.extra.ticket)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_3 = _a.sent();
                error = err_3;
                errorData = error.systemData;
                failedParameters = errorData
                    ? getFailedParameters(payload.parameters, payload.values, errorData)
                    : undefined;
                failedComponents = errorData
                    ? getFailedComponents(payload.parameters, payload.values, errorData)
                    : undefined;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        error: {
                            message: error.message,
                            type: error.type,
                            extra: {
                                parameters: failedParameters,
                                components: failedComponents,
                            },
                        },
                        data: errorData,
                    })];
            case 3: return [2 /*return*/];
        }
    });
}); }, {
    condition: function (_, thunkApi) {
        var systemData = thunkApi.getState().systemDataDefineParameters;
        return !systemData.dataState.isLoading;
    },
});
var clearParameters = createAsyncThunk("system-data/clearParameters", function (parameters, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var clearFn, response, err_4, error, errorData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                clearFn = function () {
                    return parameters.reduce(function (previousPromise, currentParameter) {
                        return previousPromise.then(function () {
                            return api.clearParameter(thunkApi.extra.configId, currentParameter, thunkApi.extra.ticket);
                        });
                    }, Promise.resolve(getEmptySystemData()));
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, clearFn()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response];
            case 3:
                err_4 = _a.sent();
                error = err_4;
                errorData = error.systemData;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        error: {
                            message: error.message,
                            type: error.type,
                            extra: error.extra,
                        },
                        data: errorData,
                    })];
            case 4: return [2 /*return*/];
        }
    });
}); }, {
    condition: function (_, thunkApi) {
        var systemData = thunkApi.getState().systemDataDefineParameters;
        return !systemData.dataState.isLoading;
    },
});
var selectDefaultCentralPump = createAsyncThunk("system-data/selectDefaultCentralPump", function (nodeId, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, defaultPump, selectionId;
    return __generator(this, function (_a) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        defaultPump = Object.values(systemData.dataState.data.vacuumPumpComponents).find(function (component) {
            return component.valid &&
                component.value !== systemDataConstants.parameterValues.noPumpSelected;
        });
        selectionId = systemData.pumpSelectionCentralMap[nodeId];
        if (defaultPump && selectionId) {
            thunkApi.dispatch(setParameters({
                parameters: [selectionId],
                values: [defaultPump.value],
            }));
        }
        return [2 /*return*/];
    });
}); });
var selectCup = createAsyncThunk("system-data/selectCup", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, selectionIds, cupId;
    var _a;
    return __generator(this, function (_b) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        selectionIds = payload.nodeIds.map(function (nodeId) { return systemData.cupSelectionMap[nodeId]; });
        cupId = systemData.dataState.data.suctionCupComponents[(_a = payload.value) !== null && _a !== void 0 ? _a : "".concat(ComponentList.SUCTION_CUP, "_X")].value;
        if (selectionIds.length > 0 && cupId) {
            thunkApi.dispatch(setParameters({
                parameters: selectionIds,
                values: selectionIds.map(function () { return payload.value; }),
            }));
        }
        return [2 /*return*/, {
                selectedCup: cupId,
            }];
    });
}); });
var selectCupMounting = createAsyncThunk("system-data/selectCupMounting", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, selectionId;
    return __generator(this, function (_a) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        selectionId = systemData.cupMountingSelectionMap[payload.nodeId];
        if (selectionId) {
            thunkApi.dispatch(setParameters({
                parameters: [selectionId],
                values: [payload.value],
            }));
        }
        return [2 /*return*/];
    });
}); });
var selectCentralPump = createAsyncThunk("system-data/selectCentralPump", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, selectionIds;
    return __generator(this, function (_a) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        if (payload.nodeIds.length === 0)
            return [2 /*return*/];
        selectionIds = payload.nodeIds.map(function (nodeId) { return systemData.pumpSelectionCentralMap[nodeId]; });
        thunkApi.dispatch(setParameters({
            parameters: selectionIds,
            values: selectionIds.map(function () { return payload.value; }),
        }));
        return [2 /*return*/];
    });
}); });
var selectDecentralPump = createAsyncThunk("system-data/selectDecentralPump", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, selectionId;
    return __generator(this, function (_a) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        selectionId = systemData.pumpSelectionDecentralMap[payload.nodeId];
        if (selectionId) {
            thunkApi.dispatch(setParameters({
                parameters: [selectionId],
                values: [payload.value],
            }));
        }
        return [2 /*return*/];
    });
}); });
var deselectAllComponents = createAsyncThunk("system-data/deselectAllComponents", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, selectionIdsToClear, getIdToClear, payload_1, payload_1_1, nodeId;
    var e_1, _a;
    return __generator(this, function (_b) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        selectionIdsToClear = [];
        getIdToClear = function (nodeId, selectionMap, selections) {
            var selectionId = selectionMap[nodeId];
            if (selectionId && selections[selectionId]) {
                selectionIdsToClear.push(selectionId);
            }
        };
        try {
            for (payload_1 = __values(payload), payload_1_1 = payload_1.next(); !payload_1_1.done; payload_1_1 = payload_1.next()) {
                nodeId = payload_1_1.value;
                getIdToClear(nodeId, systemData.cupSelectionMap, systemData.dataState.data.suctionCupSelections);
                getIdToClear(nodeId, systemData.cupMountingSelectionMap, systemData.dataState.data.cupMountingSelections);
                getIdToClear(nodeId, systemData.pumpSelectionCentralMap, systemData.dataState.data.vacuumPumpSelectionsCentralized);
                getIdToClear(nodeId, systemData.pumpSelectionDecentralMap, systemData.dataState.data.vacuumPumpSelectionsDecentralized);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (payload_1_1 && !payload_1_1.done && (_a = payload_1.return)) _a.call(payload_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (selectionIdsToClear.length > 0) {
            thunkApi.dispatch(clearParameters(selectionIdsToClear));
        }
        return [2 /*return*/];
    });
}); });
var duplicateSelections = createAsyncThunk("system-data/duplicateSelections", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var systemData, parameterNames, parameterValues, getParameterToSet;
    return __generator(this, function (_a) {
        systemData = thunkApi.getState().systemDataDefineParameters;
        if (!systemData.dataState.data)
            return [2 /*return*/];
        parameterNames = [];
        parameterValues = [];
        getParameterToSet = function (selectionMap, selections) {
            var fromSelectionId = selectionMap[payload.fromNodeId];
            var toSelectionId = selectionMap[payload.toNodeId];
            if (toSelectionId && fromSelectionId && selections[fromSelectionId]) {
                parameterNames.push(toSelectionId);
                parameterValues.push(selections[fromSelectionId]);
            }
        };
        getParameterToSet(systemData.cupSelectionMap, systemData.dataState.data.suctionCupSelections);
        getParameterToSet(systemData.cupMountingSelectionMap, systemData.dataState.data.cupMountingSelections);
        getParameterToSet(systemData.pumpSelectionCentralMap, systemData.dataState.data.vacuumPumpSelectionsCentralized);
        getParameterToSet(systemData.pumpSelectionDecentralMap, systemData.dataState.data.vacuumPumpSelectionsDecentralized);
        if (parameterNames.length > 0) {
            thunkApi.dispatch(setParameters({ parameters: parameterNames, values: parameterValues }));
        }
        return [2 /*return*/];
    });
}); });
var clearLatestSelectedCup = function (state) {
    state.latestSelectedCup = undefined;
};
var systemDataSlice = createSlice({
    name: "system-data",
    initialState: initialState,
    reducers: {
        assignCupSelection: assignCupSelection,
        assignCupMountingSelection: assignCupMountingSelection,
        assignDecentralPumpSelection: assignDecentralPumpSelection,
        assignCentralPumpSelection: assignCentralPumpSelection,
        unassignAllSelections: unassignAllSelections,
        clearLatestSelectedCup: clearLatestSelectedCup,
        setCardboardManualThroughLeak: setCardboardManualThroughLeak,
        setCardboardManualSurfaceRoughness: setCardboardManualSurfaceRoughness,
        setSystemDataDefineCurrentStep: setSystemDataDefineCurrentStep,
    },
    extraReducers: function (builder) {
        builder.addCase(getData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dataState = { data: payload, isLoading: false };
        });
        builder.addCase(getData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.dataState.errors = payload === null || payload === void 0 ? void 0 : payload.errors;
            state.dataState.isLoading = false;
            if (payload === null || payload === void 0 ? void 0 : payload.data) {
                state.dataState.data = payload.data;
            }
        });
        builder.addCase(getData.pending, function (state) {
            state.dataState.isLoading = true;
        });
        builder.addCase(setParameters.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dataState = { data: payload, isLoading: false };
        });
        builder.addCase(setParameters.rejected, function (state, _a) {
            var payload = _a.payload;
            state.dataState.errors = (payload === null || payload === void 0 ? void 0 : payload.error) ? [payload.error] : undefined;
            state.dataState.isLoading = false;
            if (payload === null || payload === void 0 ? void 0 : payload.data) {
                state.dataState.data = payload.data;
            }
        });
        builder.addCase(setParameters.pending, function (state) {
            state.dataState.isLoading = true;
        });
        builder.addCase(clearParameters.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dataState = { data: payload, isLoading: false };
        });
        builder.addCase(clearParameters.rejected, function (state, _a) {
            var payload = _a.payload;
            state.dataState.errors = (payload === null || payload === void 0 ? void 0 : payload.error) ? [payload.error] : undefined;
            state.dataState.isLoading = false;
            if (payload === null || payload === void 0 ? void 0 : payload.data) {
                state.dataState.data = payload.data;
            }
        });
        builder.addCase(clearParameters.pending, function (state) {
            state.dataState.isLoading = true;
        });
        builder.addCase(selectDefaultCentralPump.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(selectCup.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(selectCup.fulfilled, function (state, _a) {
            var payload = _a.payload;
            if ((payload === null || payload === void 0 ? void 0 : payload.selectedCup) !== state.latestSelectedCup) {
                state.latestSelectedCup = payload === null || payload === void 0 ? void 0 : payload.selectedCup;
            }
        });
        builder.addCase(selectCupMounting.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(selectCentralPump.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(selectDecentralPump.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(deselectAllComponents.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
        builder.addCase(duplicateSelections.rejected, function (_, _a) {
            var error = _a.error;
            console.error(error);
            Sentry.captureException(error);
        });
    },
});
var allActions = systemDataSlice.actions, reducer = systemDataSlice.reducer;
export var callableActions = {
    setSystemDataDefineCurrentStep: systemDataSlice.actions.setSystemDataDefineCurrentStep,
    getSystemDataDefineParameters: getData,
    setParametersDefineParameters: setParameters,
    clearParameters: clearParameters,
    selectCupMounting: selectCupMounting,
    selectCentralPump: selectCentralPump,
    selectDecentralPump: selectDecentralPump,
    clearLatestSelectedCup: allActions.clearLatestSelectedCup,
    setCardboardManualThroughLeak: allActions.setCardboardManualThroughLeak,
    setCardboardManualSurfaceRoughness: allActions.setCardboardManualSurfaceRoughness,
};
export var internalActions = {
    assignCupSelection: allActions.assignCupSelection,
    assignCupMountingSelection: allActions.assignCupMountingSelection,
    assignDecentralPumpSelection: allActions.assignDecentralPumpSelection,
    assignCentralPumpSelection: allActions.assignCentralPumpSelection,
    unassignAllSelections: allActions.unassignAllSelections,
    selectDefaultCentralPump: selectDefaultCentralPump,
    deselectAllComponents: deselectAllComponents,
    duplicateSelections: duplicateSelections,
    selectCup: selectCup,
};
export default reducer;
