var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var componentListGroupName = "component_lists_group";
export var componentSelectionGroupName = "component_selected_group";
export var outputGroupName = "output_group";
export var calculationInputGroupName = "calculation_input_group";
export var cardboardBoxSpecificationGroupName = "cardboard_box_specification_group";
export var adminGroupName = "admin_group";
export var applicationGroupName = "application_group";
export var totalLiftingForceHorizontalFieldName = "total_lifting_force_horizontal_field";
export var totalLiftingForceVerticalFieldName = "total_lifting_force_vertical_field";
export var requiredLiftingForceHorizontalFieldName = "required_lifting_force_horizontal_field";
export var requiredLiftingForceVerticalFieldName = "required_lifting_force_vertical_field";
export var totalVolumeFieldName = "total_volume_dm3";
export var releaseNotesGroupName = "release_note_group";
export var systemTemplateGroupName = "system_template_group";
export var systemTemplateSelectedFieldName = "system_template_selected_field";
export var systemTemplateNoneSelected = "system_template_XXX";
export var handledObjectTypeFieldName = "handled_object_type_field";
export var defaultSurfaceRoughnessFieldName = "surface_roughness_field";
export var cardboardSurfaceRoughnessFieldName = "cardboard_surface_roughness_field";
export var surfaceStructureFieldName = "surface_structure_field";
export var defaultThroughLeakFieldName = "porosity_index_field";
export var cardboardThroughLeakFieldName = "cardboard_porosity_index_field";
export var surfaceCoatingFieldName = "surface_coating_field";
export var positiveInfinity = "posinf";
export var negativeInfinity = "neginf";
export var cardboardParameterNames = {
    paperCoating: "paper_coating_outer_field",
    cardboardCode: "cardboard_code_field",
    cardboardTape: "tape_field",
    cardboardTapeEnds: "end_tape_field",
    cardboardFlute: "corrugated_carton_type_field",
};
export var parameterNames = __assign({ feedPressure: "est_system_feed_pressure_field", vacuumSystemType: "vacuum_system_field", partSecuredLevel: "desired_part_secured_vacuum_level_field", energySavingLevel: "desired_final_vacuum_level_field", energySavingRestartLevel: "energy_saving_restart_level_field", blowOffClearanceTime: "blow_off_clearence_time_field", targetPartsPerMinute: "target_parts_per_minute_field", vacuumOnBeforePick: "vacuum_on_before_touch_field", blowOffOnBeforePick: "cups_floating_before_pickup_field", compressedAirConnections: "single_compressed_air_line_field", energySavingToggle: "energy_saving_system_vacuum_field", safetyFactorVertical: "safety_factor_vertical_field", safetyFactorHorizontal: "safety_factor_horizontal_field", applicationField: "application_field", texturedSurface: "good_for_textured_surfaces_field" }, cardboardParameterNames);
export var tapeEndsParameterValues = {
    withTape: "Yes",
    withoutTape: "No",
};
export var tapeParameterValues = {
    noTape: "cardboard_tape_no",
    singleCenter: "cardboard_tape_single_center",
    doubleCenter: "cardboard_tape_double_center",
    left: "cardboard_tape_left_side",
    right: "cardboard_tape_right_side",
    doubleLeft: "cardboard_tape_double_left_side",
    doubleRight: "cardboard_tape_double_right_side",
    centerCenter: "cardboard_tape_center_center",
};
export var paperCoatingParameterValues = {
    rawNatural: "cardboard_coating_raw_natural",
    rawColored: "cardboard_coating_raw_colored",
    coated: "cardboard_coating_coated",
    painted: "cardboard_coating_painted",
};
export var parameterValues = __assign(__assign(__assign({ centralizedSystem: "vacuum_system_centralized", decentralizedSystem: "vacuum_system_decentralized", doubleCompressedAirConnections: "Double", noPumpSelected: "vac_gen_X", energySavingOn: "Yes", texturedSurfaceOn: "Yes" }, paperCoatingParameterValues), tapeParameterValues), tapeEndsParameterValues);
export var defaultCupImageMap = {
    DCF: "0210590.png",
    FCF: "0201827.png",
    BFFT: "0210557.png",
    BFF: "0201821.png",
    BXF: "0207749.png",
    OCF: "0207728.png",
    OBF: "0207597.png",
    PAF: "S.PAF30NP60.G38M.01.png",
    PAB: "S.PAB30NP60.G38M.01.png",
    PAOF: "S.PAOF16X50NP60.G38M.01.png",
    PAOB: "S.PAOB30X60NP60.G38MS.01.png",
    PAXB: "S.PAXB32NP60.G38M.01.png",
    other: "default_image.png",
};
