export var getEmptySystemData = function () { return ({
    currentStep: false,
    parameters: {},
    parameterGroups: {},
    parameterSubGroups: [],
    suctionCupComponents: {},
    suctionCupSelections: {},
    vacuumPumpComponents: {},
    vacuumPumpSelectionsCentralized: {},
    vacuumPumpSelectionsDecentralized: {},
    cupMountingComponents: {},
    cupMountingSelections: {},
    output: {
        totalLiftingForceVertical: 0,
        totalLiftingForceHorizontal: 0,
        requiredLiftingForceVertical: 0,
        requiredLiftingForceHorizontal: 0,
        totalVolume: 0,
    },
    calculationInput: {
        handledObjectType: "",
        surfaceRoughness: 0,
        surfaceStructure: "",
        throughLeak: 0,
        surfaceCoating: "",
    },
}); };
