var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { Button, useModal } from "@animech/ui";
import * as Styled from "./material-select-tab.style";
import { PageRoute } from "utility/url";
import { SelectApplicationMode, useSelectApplication, } from "hooks/select-application";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { MaterialSelect } from "ui/components/elements/material-select/material-select";
import { ImageSelectContainer } from "ui/components/elements/input/image-select/image-select-container";
import { TabHeader, TabSubHeader, } from "ui/components/panels/home-menu/tabs/tab-header";
import { LoadingModal } from "ui/components/modals/loading-modal/loading-modal";
export var MaterialSelectTab = function () {
    var _a = __read(useState(""), 2), selectedApplication = _a[0], setSelectedApplication = _a[1];
    var handleApplicationSelection = useSelectApplication().handleApplicationSelection;
    var t = useTranslation().t;
    var openModal = useModal();
    var hasStartedBuildingSystem = useAppSelector(selectors.selectHasStartedBuildingSystem);
    var currentRoute = useLocation().pathname;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var selectApplication = function (application) {
        setSelectedApplication(application);
    };
    var mode;
    var startFromBlankSystem = currentRoute === PageRoute.BLANK_SYSTEM;
    if (startFromBlankSystem) {
        mode = hasStartedBuildingSystem
            ? SelectApplicationMode.RESET_AFTER_ASKING
            : SelectApplicationMode.RESET_WITHOUT_ASKING;
    }
    else {
        mode = hasStartedBuildingSystem
            ? SelectApplicationMode.RESET_OR_KEEP
            : SelectApplicationMode.KEEP_WITHOUT_ASKING;
    }
    var onConfirm = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var reset, loadingResetConfigurationModal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleApplicationSelection(selectedApplication, {
                        mode: mode,
                        confirmationModalHeader: startFromBlankSystem
                            ? t("reset_modal.restart_title")
                            : undefined,
                        confirmationModalMessage: startFromBlankSystem
                            ? t("reset_modal.restart_message")
                            : undefined,
                    })];
                case 1:
                    reset = _a.sent();
                    if (!reset) return [3 /*break*/, 3];
                    loadingResetConfigurationModal = openModal(_jsx(LoadingModal, { translationKey: "home-menu.material.loading-material" }));
                    return [4 /*yield*/, dispatch(actions.getSystemDataDefineParameters())];
                case 2:
                    _a.sent();
                    loadingResetConfigurationModal.close();
                    navigate(PageRoute.SYSTEM_DESIGN);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        dispatch,
        handleApplicationSelection,
        mode,
        navigate,
        openModal,
        selectedApplication,
        startFromBlankSystem,
        t,
    ]);
    return (_jsxs(Styled.MaterialSelectTab, { children: [_jsxs(Styled.MaterialSelectForm, { children: [_jsx(TabHeader, { header: currentRoute === PageRoute.BLANK_SYSTEM
                            ? t("home-menu.blank-system.header")
                            : t("home-menu.material.header") }), _jsx(TabSubHeader, { header: currentRoute === PageRoute.BLANK_SYSTEM
                            ? t("home-menu.blank-system.sub-header")
                            : t("home-menu.material.sub-header") }), _jsx(ImageSelectContainer, { children: _jsx(MaterialSelect, { onSelectMaterial: selectApplication, value: selectedApplication }) })] }), _jsx(Styled.MaterialSelectConfirm, { children: _jsx(Button, { onClick: onConfirm, disabled: !selectedApplication, children: t("home-menu.select") }) })] }));
};
